<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/img13.png" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/img14.png" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/img15.png" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/img16.png" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/img17.png" alt="image">
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
